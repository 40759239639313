import React, { useContext } from "react";
import { LayoutContext } from "../../hooks/layout-context";
import { useTranslation } from "react-i18next";
import CarForm from "../../components/forms/car-form";

export default function CarsAdd() {
  const { t } = useTranslation();
  const { layout, setLayout } = useContext(LayoutContext);

  React.useEffect(() => {
    layout.sideMenu = "cars.add";
    layout.breadcrumbs = [
      { label: t("Pojazdy"), path: "/cars" },
      { path: null, label: t("Dodaj") },
    ];
    setLayout({ ...layout });
  }, []);

  return <CarForm asTuning={false} />;
}
