import { useContext } from "react";
import axios from "axios";
import { API_URL } from "../config";
import { getHeaderOptions } from "../lib/request-helper";
import { ShowLoaderContext } from "./show-loader-context";
import { Employee } from "../types/employee";
import { FormInputEmployee } from "../types/forms";

export default function useEmployee() {
  const { setShowLoader } = useContext(ShowLoaderContext);

  const listing = async (
    page: number,
    perPage: number,
    search: string = "",
    sortField: string | null = null,
    sortDirection: string | null = null
  ): Promise<any> => {
    setShowLoader(true);
    try {
      const result = await axios.get(
        `${API_URL}/employee?page=${page}&perPage=${perPage}&search=${search}&sortProp=${sortField}&sortDir=${sortDirection}`,
        getHeaderOptions()
      );
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const getById = async (id: string): Promise<Employee> => {
    setShowLoader(true);
    try {
      const result = await axios.get(`${API_URL}/employee/${id}`, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const createEmployee = async (inputData: FormInputEmployee): Promise<Employee> => {
    setShowLoader(true);
    try {
      const result = await axios.post(`${API_URL}/employee`, inputData, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const changePassword = async (id: string, inputData: FormInputEmployee): Promise<Employee> => {
    setShowLoader(true);
    try {
      const result = await axios.post(`${API_URL}/employee/change-password/${id}`, inputData, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const updateEmployee = async (id: string, inputData: FormInputEmployee | { active: boolean }): Promise<Employee> => {
    setShowLoader(true);
    try {
      const result = await axios.put(`${API_URL}/employee/${id}`, inputData, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const forceDeleteEmployee = async (id: number | string): Promise<any> => {
    setShowLoader(true);
    try {
      const result = await axios.delete(`${API_URL}/employee/${id}`, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const updateAvatar = async (id: string, inputData: any): Promise<Employee> => {
    setShowLoader(true);
    try {
      const result = await axios.put(`${API_URL}/employee/${id}/file`, inputData, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const assignClient = async (id: string, clientId: string): Promise<Employee> => {
    setShowLoader(true);
    try {
      const result = await axios.put(`${API_URL}/employee/${id}/assign/client/${clientId}`, {}, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  return {
    listing,
    getById,
    changePassword,
    createEmployee,
    updateEmployee,
    forceDeleteEmployee,
    updateAvatar,
    assignClient,
  };
}
