import React, { useState } from "react";

interface SideBoxProps {
  children?: any;
  onClose: Function;
}

export default function SideBox({ children, onClose }: SideBoxProps) {
  const [shouldClose, setShouldClose] = useState<boolean>(false);
  const [enterAnimation, setEnterAnimation] = useState<boolean>(false);

  React.useEffect(() => {
    setTimeout(() => {
      setEnterAnimation(true);
    }, 100);
  }, []);

  const handleClose = () => {
    setShouldClose(true);
    setTimeout(() => {
      onClose();
    }, 350);
  };

  return (
    <>
      <div
        className={"side-box--overlay" + (enterAnimation ? " enter-animation" : "") + (shouldClose ? " closing" : "")}
        onClick={handleClose}
      ></div>
      <div
        className={"side-box--content" + (enterAnimation ? " enter-animation" : "") + (shouldClose ? " closing" : "")}
      >
        <div className={"position-relative"}>
          <i className="side-box--close mdi mdi-close me-2" onClick={handleClose}></i>
          <div>{children}</div>
        </div>
      </div>
    </>
  );
}
