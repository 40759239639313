import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { ToastsContext } from "../../hooks/toasts-context";
import useCarNotes from "../../hooks/use-car-notes";
import { FormInputCarNote } from "../../types/forms";
import { generate } from "../../lib/random-id-lib";
import { Car } from "../../types/car";

interface CarNoteFormProps {
  car: Car;
  onSuccess: Function;
}

export default function CarNoteForm({ car, onSuccess }: CarNoteFormProps) {
  const { t } = useTranslation();
  const { createCarNote } = useCarNotes();
  const {
    setValue,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    reset,
  } = useForm<FormInputCarNote>();
  const { toasts, setToasts } = useContext(ToastsContext);

  const handleLogin = async (data: any) => {
    console.log("data", data);
    try {
      data.car = car.id;
      await createCarNote(car.id!!, data);
      toasts.push({ title: t("Sukces"), content: t("Dane zapisane"), show: true, id: generate(32), type: "success" });
      setToasts([...toasts]);
      reset();
      onSuccess();
    } catch (e) {
      console.log(e);
      toasts.push({
        title: t("Błąd"),
        content: t("Nie udało się zapisać"),
        show: true,
        id: generate(32),
        type: "danger",
      });
      setToasts([...toasts]);
    }
  };

  return (
    <form onSubmit={handleSubmit(handleLogin)}>
      <div className="row">
        <div className="col-12 mb-4">
          <div className="form-group position-relative">
            <label htmlFor="user_name" className="form-label">
              {t("Nazwa")}
            </label>
            <textarea
              className={"form-control" + (errors && errors.content !== undefined ? " has-error" : "")}
              id="title"
              {...register("content", { required: true })}
              defaultValue={""}
            />
            {errors && errors.content !== undefined ? (
              <span className={"error-message"}>{t(errors.content?.type)}</span>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 text-end">
          <button className="btn btn-outline-primary">{t("Dodaj")}</button>
        </div>
      </div>
    </form>
  );
}
