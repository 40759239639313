import { useContext } from "react";
import axios from "axios";
import { API_URL, apiKeyGus, apiUrlGus } from "../config";
import { getHeaderOptions } from "../lib/request-helper";
import { ShowLoaderContext } from "./show-loader-context";
import { FormInputClient } from "../types/forms";
import { Client } from "../types/client";

export default function useClient() {
  const { setShowLoader } = useContext(ShowLoaderContext);

  const listing = async (
    page: number,
    perPage: number,
    search: string = "",
    sortField: string | null = null,
    sortDirection: string | null = null
  ): Promise<any> => {
    setShowLoader(true);
    try {
      const result = await axios.get(
        `${API_URL}/client?page=${page}&perPage=${perPage}&search=${search}&sortProp=${sortField}&sortDir=${sortDirection}`,
        getHeaderOptions()
      );
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const listingSimpleClients = async (query = ""): Promise<any> => {
    setShowLoader(true);
    try {
      const result = await axios.get(`${API_URL}/client/simple?search=${query}`, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const getById = async (id: string): Promise<Client> => {
    setShowLoader(true);
    try {
      const result = await axios.get(`${API_URL}/client/${id}`, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const createClient = async (inputData: FormInputClient): Promise<Client> => {
    setShowLoader(true);
    try {
      const result = await axios.post(`${API_URL}/client`, inputData, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const updateClient = async (id: string, inputData: FormInputClient): Promise<Client> => {
    setShowLoader(true);
    try {
      const result = await axios.put(`${API_URL}/client/${id}`, inputData, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const forceDeleteClient = async (id: string): Promise<Client> => {
    setShowLoader(true);
    try {
      const result = await axios.delete(`${API_URL}/client/${id}`, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const exportClients = (): void => {
    window.open(`${API_URL}/client/export`, "_blank")!!.focus();
  };

  const apiGusLogin = async (nip: string) => {
    try {
      const result = await axios.post(`${API_URL}/client/find/remote`, { nip }, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  return {
    listing,
    listingSimpleClients,
    getById,
    createClient,
    updateClient,
    forceDeleteClient,
    exportClients,
    apiGusLogin,
  };
}
