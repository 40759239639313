import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface ConfirmationProps {
  showConfirmation: boolean;
  onClose: Function;
  onConfirm: Function;
  content: string;
}

export default function Confirmation({ showConfirmation = false, onConfirm, onClose, content }: ConfirmationProps) {
  const { t } = useTranslation();

  return (
    <Modal show={showConfirmation}>
      <Modal.Header closeButton>
        <Modal.Title>{t("Potwierdzenie")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{content}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => onClose()}>
          {t("Anuluj")}
        </Button>
        <Button variant="primary" onClick={() => onConfirm()}>
          {t("Potwierdzam")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
