import { createContext } from "react";
import { ToastItem } from "../types/toast";

export interface ToastsContextData {
  toasts: Array<ToastItem>;
  setToasts: (toasts: Array<ToastItem>) => void;
}

const toastsContextDefaultValue: ToastsContextData = {
  setToasts: () => null,
  toasts: [],
};

export const ToastsContext = createContext<ToastsContextData>(toastsContextDefaultValue);
