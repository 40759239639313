import React, { useState } from "react";
import { Car, CarNote } from "../types/car";
import { useTranslation } from "react-i18next";
import CarNoteForm from "./forms/car-note-form";
import useCarNotes from "../hooks/use-car-notes";

interface NotesListProps {
  car: Car;
  onNoteCreate: any;
}

export default function NotesList({ car, onNoteCreate }: NotesListProps) {
  const { t } = useTranslation();
  const [notes, setNotes] = useState<Array<CarNote>>([]);
  const { listing } = useCarNotes();

  React.useEffect(() => {
    (async () => {
      await loadListing();
    })();
  }, []);

  const loadListing = async () => {
    try {
      const rows = await listing(car.id!!);
      setNotes(rows);
    } catch (e) {
      console.log(e);
    }
  };

  const onSuccess = () => {
    loadListing();
    if (onNoteCreate !== undefined && onNoteCreate !== null) {
      onNoteCreate();
    }
  };

  return car !== undefined && car !== null ? (
    <div>
      <h4>
        {t("Notatki")}: {car.CarBrand?.title} {car.CarModel?.title} ({car.registrationNumber})
      </h4>
      <div className={"mb-4"}>
        <CarNoteForm car={car} onSuccess={onSuccess} />
      </div>
      {notes.map((note, index) => (
        <div className={"card shadow note--container mb-4"} key={`CarNote${index}`}>
          <div className={"card-body"}>
            <div className={"note--content"}>{note.content}</div>
            <div className={"note--footer d-flex align-items-center justify-content-between"}>
              <div className={"note--date"}>{note.createdAt!!.toString()}</div>
              <div className={"note--author"}>{note.CreatedBy!!.firstName}</div>
            </div>
          </div>
        </div>
      ))}
    </div>
  ) : (
    <></>
  );
}
