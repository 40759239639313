import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { LayoutContext } from "../hooks/layout-context";
import { UserContext } from "../hooks/user-context";
import { useParams } from "react-router-dom";
import useEmployee from "../hooks/use-employee";
import { Employee } from "../types/employee";
import EmployeeForm from "../components/forms/employee-form";

export default function MyAccount() {
  const { t } = useTranslation();
  const { layout, setLayout } = useContext(LayoutContext);
  const { user } = useContext(UserContext);
  const params = useParams();
  const { getById } = useEmployee();
  const [employee, setEmployee] = useState<Employee | null>();

  React.useEffect(() => {
    console.log("params", params);
    (async () => {
      const result = await getById(user.id!!);
      setEmployee(result);
    })();
  }, []);

  React.useEffect(() => {
    layout.sideMenu = "my-account";
    layout.breadcrumbs = [{ path: null, label: t("Moje konto") }];
    setLayout({ ...layout });
  }, []);

  return employee !== undefined && employee !== null ? (
    <EmployeeForm asMyAccount={true} id={employee.id || null} employee={employee} />
  ) : (
    <></>
  );
}
