import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { UserContext } from "../hooks/user-context";
import Loading from "./../components/loading";

interface PrivateRouteProps {
  permission: string | null;
  children?: any;
}

export default function PrivateRoute(props: PrivateRouteProps) {
  const { user, isLoading } = useContext(UserContext);

  if (isLoading) {
    return <Loading />;
  }
  if (user) {
    if (props.permission === null || user.permissions.indexOf(props.permission) !== -1) {
      return props.children;
    } else {
      return <Navigate to="/missing-permission" />;
    }
  }

  return <Navigate to="/login" />;
}
