import React, { useContext, useState } from "react";
import { LayoutContext } from "../../../hooks/layout-context";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useCarModel from "../../../hooks/use-car-model";
import { CarModel } from "../../../types/car";
import CarModelForm from "../../../components/forms/car-model-form";

export default function CarModelEdit() {
  const { t } = useTranslation();
  const { layout, setLayout } = useContext(LayoutContext);
  const params = useParams();
  const { getById } = useCarModel();
  const [carModel, setCarModel] = useState<CarModel | null>();

  React.useEffect(() => {
    console.log("params", params);
    if (params !== undefined && params !== null) {
      if (params.id !== undefined && params.id !== null) {
        (async () => {
          const result = await getById(params.id!!);
          setCarModel(result);
        })();
      }
    }
  }, []);

  React.useEffect(() => {
    layout.sideMenu = "settings";
    layout.breadcrumbs = [
      { label: t("Ustawienia"), path: "/settings" },
      {
        label: t("Model pojazdu"),
        path: "/settings/car/model",
      },
      { path: null, label: t("Edytuj") },
    ];
    setLayout({ ...layout });
  }, []);

  return carModel !== undefined && carModel !== null ? (
    <CarModelForm id={params.id || null} carModel={carModel} />
  ) : (
    <></>
  );
}
