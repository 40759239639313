import React, { useContext, useState } from "react";
import { LayoutContext } from "../../hooks/layout-context";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ClientForm from "../../components/forms/client-form";
import useClient from "../../hooks/use-client";
import { Client } from "../../types/client";

export default function ClientEdit() {
  const { t } = useTranslation();
  const { layout, setLayout } = useContext(LayoutContext);
  const params = useParams();
  const { getById } = useClient();
  const [client, setClient] = useState<Client | null>();

  React.useEffect(() => {
    console.log("params", params);
    if (params !== undefined && params !== null) {
      if (params.id !== undefined && params.id !== null) {
        (async () => {
          const result = await getById(params.id!!);
          setClient(result);
        })();
      }
    }
  }, []);

  React.useEffect(() => {
    layout.sideMenu = "clients";
    layout.breadcrumbs = [
      { label: t("Klienci"), path: "/clients" },
      { path: null, label: t("Edytuj") },
    ];
    setLayout({ ...layout });
  }, []);

  return client !== undefined && client !== null ? (
    <ClientForm id={params.id || null} client={client} employeeId={null} />
  ) : (
    <></>
  );
}
