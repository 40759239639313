import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { LayoutContext } from "../../hooks/layout-context";

export default function Breadcrumbs() {
  const { layout } = useContext(LayoutContext);

  return (
    <div className="page-title-right">
      <ol className="breadcrumb m-0">
        <li className={"breadcrumb-item" + (layout.breadcrumbs && layout.breadcrumbs.length === 0 ? " active" : "")}>
          {layout.breadcrumbs && layout.breadcrumbs.length > 0 ? <Link to={"/"}>TechTir</Link> : "TechTir"}
        </li>
        {layout.breadcrumbs ? (
          layout.breadcrumbs.map((it, index) => (
            <li
              key={`breadcrumb${index + 1}`}
              className={"breadcrumb-item" + (index + 1 === layout.breadcrumbs.length ? " active" : "")}
            >
              {it.path !== undefined && it.path !== null ? <Link to={it.path!!}>{it.label}</Link> : it.label}
            </li>
          ))
        ) : (
          <></>
        )}
      </ol>
    </div>
  );
}
