import React, { Suspense } from "react";
import "./App.scss";
import "./i18n";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { UserContext } from "./hooks/user-context";
import { ShowLoaderContext } from "./hooks/show-loader-context";
import { LayoutContext } from "./hooks/layout-context";
import { ToastsContext } from "./hooks/toasts-context";
import PrivateRoute from "./pages/private-route";
import Template from "./components/layout/template";
import Login from "./pages/login";
import Home from "./pages/home";
import Landing from "./pages/landing";
import NotFound from "./pages/not-found";
import useShowLoader from "./hooks/use-show-loader";
import useAuth from "./hooks/use-auth";
import useLayout from "./hooks/use-layout";
import ForgetPassword from "./pages/forget-password";
import ClientsList from "./pages/clients/clients-list";
import EmployeeList from "./pages/employee/employee-list";
import EmployeeAdd from "./pages/employee/employee-add";
import CarsAdd from "./pages/cars/cars-add";
import CarsList from "./pages/cars/cars-list";
import EmployeeEdit from "./pages/employee/employee-edit";
import ClientAdd from "./pages/clients/client-add";
import ClientEdit from "./pages/clients/client-edit";
import useToasts from "./hooks/use-toasts";
import Settings from "./pages/settings/settings";
import CarTypeList from "./pages/settings/car-type/car-type-list";
import CarTypeAdd from "./pages/settings/car-type/car-type-add";
import CarTypeEdit from "./pages/settings/car-type/car-type-edit";
import CarBrandList from "./pages/settings/car-brand/car-brand-list";
import CarBrandAdd from "./pages/settings/car-brand/car-brand-add";
import CarBrandEdit from "./pages/settings/car-brand/car-brand-edit";
import CarGearBoxList from "./pages/settings/car-gear-box/car-gear-box-list";
import CarGearBoxAdd from "./pages/settings/car-gear-box/car-gear-box-add";
import CarGearBoxEdit from "./pages/settings/car-gear-box/car-gear-box-edit";
import CarModelList from "./pages/settings/car-model/car-model-list";
import CarModelAdd from "./pages/settings/car-model/car-model-add";
import CarModelEdit from "./pages/settings/car-model/car-model-edit";
import CarsEdit from "./pages/cars/cars-edit";
import MyAccount from "./pages/my-account";
import MissingPermission from "./pages/missing-permission";
import TuningList from "./pages/cars/tuning-list";
import TuningAdd from "./pages/cars/tuning-add";
import TuningEdit from "./pages/cars/tuning-edit";
import { socket, SocketContext } from "./hooks/socket-context";
import "react-bootstrap-typeahead/css/Typeahead.css";
import EmployeeAssignClient from "./pages/employee/employee-assign-client";
import CarsView from "./pages/cars/cars-view";

function App() {
  const { showLoader, setShowLoader } = useShowLoader(false);
  const { user, setUser, isLoading, setUserContext, getProfile } = useAuth();
  const { layout, setLayout } = useLayout({});
  const { toasts, setToasts } = useToasts([]);

  React.useEffect(() => {
    (async () => {
      try {
        await getProfile();
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  React.useEffect(() => {
    console.log("changed user --- ", user);
    if (user !== undefined && user !== null) {
      socket.emit("join-room", "all");
    }
  }, [user]);

  return (
    <Suspense fallback="loading">
      <HelmetProvider>
        <Router>
          <UserContext.Provider value={{ user, setUser, isLoading, setUserContext }}>
            <ShowLoaderContext.Provider value={{ showLoader, setShowLoader }}>
              <LayoutContext.Provider value={{ layout, setLayout }}>
                <ToastsContext.Provider value={{ toasts, setToasts }}>
                  <SocketContext.Provider value={socket}>
                    <Template>
                      <Routes>
                        <Route path="/" element={<Landing />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/forget-password" element={<ForgetPassword />} />
                        <Route path="/missing-permission" element={<MissingPermission />} />
                        <Route
                          path="/home"
                          element={
                            <PrivateRoute permission={null}>
                              <Home />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/my-account"
                          element={
                            <PrivateRoute permission={null}>
                              <MyAccount />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/settings"
                          element={
                            <PrivateRoute permission={"settings"}>
                              <Settings />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/settings/car/type"
                          element={
                            <PrivateRoute permission={"settings.carType"}>
                              <CarTypeList />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/settings/car/type/add"
                          element={
                            <PrivateRoute permission={"settings.carType"}>
                              <CarTypeAdd />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/settings/car/type/edit/:id"
                          element={
                            <PrivateRoute permission={"settings.carType"}>
                              <CarTypeEdit />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/settings/car/model"
                          element={
                            <PrivateRoute permission={"settings.carModel"}>
                              <CarModelList />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/settings/car/model/add"
                          element={
                            <PrivateRoute permission={"settings.carModel"}>
                              <CarModelAdd />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/settings/car/model/edit/:id"
                          element={
                            <PrivateRoute permission={"settings.carModel"}>
                              <CarModelEdit />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/settings/car/brand"
                          element={
                            <PrivateRoute permission={"settings.carBrand"}>
                              <CarBrandList />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/settings/car/brand/add"
                          element={
                            <PrivateRoute permission={"settings.carBrand"}>
                              <CarBrandAdd />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/settings/car/brand/edit/:id"
                          element={
                            <PrivateRoute permission={"settings.carBrand"}>
                              <CarBrandEdit />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/settings/car/gear/box"
                          element={
                            <PrivateRoute permission={"settings.gearBox"}>
                              <CarGearBoxList />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/settings/car/gear/box/add"
                          element={
                            <PrivateRoute permission={"settings.gearBox"}>
                              <CarGearBoxAdd />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/settings/car/gear/box/edit/:id"
                          element={
                            <PrivateRoute permission={"settings.gearBox"}>
                              <CarGearBoxEdit />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/clients"
                          element={
                            <PrivateRoute permission={"clients"}>
                              <ClientsList />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/clients/add"
                          element={
                            <PrivateRoute permission={"clients.add"}>
                              <ClientAdd />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/clients/edit/:id"
                          element={
                            <PrivateRoute permission={"clients.add"}>
                              <ClientEdit />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/employee"
                          element={
                            <PrivateRoute permission={"employee"}>
                              <EmployeeList />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/employee/add"
                          element={
                            <PrivateRoute permission={"employee.add"}>
                              <EmployeeAdd />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/employee/:id/assign/client"
                          element={
                            <PrivateRoute permission={"employee.add"}>
                              <EmployeeAssignClient />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/employee/edit/:id"
                          element={
                            <PrivateRoute permission={"employee.add"}>
                              <EmployeeEdit />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/tuning"
                          element={
                            <PrivateRoute permission={"tuning"}>
                              <TuningList />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/tuning/add"
                          element={
                            <PrivateRoute permission={"cars.add"}>
                              <TuningAdd />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/tuning/edit/:id"
                          element={
                            <PrivateRoute permission={"cars.add"}>
                              <TuningEdit />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/cars"
                          element={
                            <PrivateRoute permission={"cars"}>
                              <CarsList />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/cars/add"
                          element={
                            <PrivateRoute permission={"cars.add"}>
                              <CarsAdd />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/cars/edit/:id"
                          element={
                            <PrivateRoute permission={"cars.add"}>
                              <CarsEdit />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/cars/view/:id"
                          element={
                            <PrivateRoute permission={"cars"}>
                              <CarsView />
                            </PrivateRoute>
                          }
                        />
                        <Route path="*" element={<NotFound />} />
                      </Routes>
                    </Template>
                  </SocketContext.Provider>
                </ToastsContext.Provider>
              </LayoutContext.Provider>
            </ShowLoaderContext.Provider>
          </UserContext.Provider>
        </Router>
      </HelmetProvider>
    </Suspense>
  );
}

export default App;
