import React, { useState } from "react";
import { Car, SocketCarMessage } from "../types/car";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import { getHeaderOptions } from "../lib/request-helper";
import { socket } from "../hooks/socket-context";
import axios from "axios";
import { API_URL } from "../config";

interface CarFilesListProps {
  car: Car;
  afterFileUpload?: Function | null;
  withUpload?: boolean;
}

export default function CarFilesList({ car, afterFileUpload = null, withUpload = true }: CarFilesListProps) {
  const { t } = useTranslation();
  const [filesBeforeUpload, setFilesBeforeUpload] = useState<Array<any>>([]);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    disabled: false,
    onDrop: (acceptedFiles) => {
      setFilesBeforeUpload([
        ...filesBeforeUpload.concat(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
              uploading: true,
              currentUploading: false,
            })
          )
        ),
      ]);
    },
  });

  const cancelFileUpload = (index: number) => {
    filesBeforeUpload.splice(index, 1);
    setFilesBeforeUpload([...filesBeforeUpload]);
  };

  const uploadSingleFile = async (carId: string, index: number, filesCounter: number) => {
    const headers: any = getHeaderOptions();
    headers.onUploadProgress = (progressEvent: any) => {
      console.log(progressEvent.loaded);
      console.log(index, filesCounter);
      console.log(progressEvent);
      let value = (progressEvent.loaded * 100) / progressEvent.total / filesCounter + (100 / filesCounter) * index;

      if (index + 1 === filesCounter) {
        if (progressEvent.loaded === progressEvent.total) {
          value = 100;
        }
      }

      const content: SocketCarMessage = {
        car: carId,
        progress: value,
        room: "all",
      };
      socket.emit("message", content);
    };

    const formData = new FormData();
    formData.set("file", filesBeforeUpload[index], filesBeforeUpload[index].name);
    await axios.post(`${API_URL}/car/${carId}/upload`, formData, headers);
  };

  const handleFileUploads = async () => {
    console.log("handleFileUploads --- carId", car.id);
    console.log("filesBeforeUpload", filesBeforeUpload);
    if (filesBeforeUpload !== undefined && filesBeforeUpload !== null && filesBeforeUpload.length > 0) {
      const filesCounter = filesBeforeUpload.length;
      for (let i = 0; i < filesCounter; i++) {
        await uploadSingleFile(car.id!!, i, filesCounter);
      }

      setFilesBeforeUpload([]);
      if (afterFileUpload !== undefined && afterFileUpload !== null) {
        afterFileUpload();
      }
    }
  };

  return car !== undefined && car !== null ? (
    <div>
      <h4>{t("Pliki")}</h4>
      {withUpload ? (
        <div>
          {car.CarBrand?.title} {car.CarModel?.title} {car.carPower} {car.CarGearBox?.title} {car.year}{" "}
          {car.registrationNumber}
        </div>
      ) : (
        <></>
      )}
      <div className={"mb-4"}></div>
      {!withUpload && car.carFiles?.length === 0 ? <div>{t("Brak plików")}</div> : <></>}
      {car.carFiles!!.map((carFile, index) => (
        <div className={"card shadow note--container mb-4"} key={`CarFile${index}`}>
          <div className={"card-body"}>
            <div className={"note--content"}>{carFile.originalFileName}</div>
            <div>
              <a href={carFile.path} target={"_blank"}>
                {t("Pobierz")}
              </a>
            </div>
            <div className={"note--footer d-flex align-items-center justify-content-between"}>
              <div className={"note--date"}>{carFile.createdAt!!.toString()}</div>
              <div className={"note--author"}>{carFile.UploadedBy!!.firstName}</div>
            </div>
          </div>
        </div>
      ))}
      {withUpload ? (
        <>
          <div className={"mb-4"}></div>
          <div className="row">
            <div className="col-12 mb-4">
              <span className="form-label d-block">{t("Dodaj plik")}</span>
              <div {...getRootProps({ className: "dropzone" })} className="dropzone">
                <div className="dz-message">
                  <i className="icon mdi mdi-cloud-upload-outline"></i>
                  <h4 className="text">{t("Upuść plik lub kliknij żeby wgrać")}</h4>
                </div>
                <input className="d-none" type="file" id="formFile" />
              </div>
              <div className="dropzone-previews mt-3">
                {filesBeforeUpload.map((file: any, index: number) => (
                  <div className="card mt-1 mb-0 shadow-none border" key={`FileBeforeUpload${index}`}>
                    <div className="p-2">
                      <div className="row align-items-center">
                        <div className="col-auto">
                          <div className="avatar-sm">
                            <span className="avatar-title rounded">
                              <i className="mdi mdi-file font-16"></i>
                            </span>
                          </div>
                        </div>
                        <div className="col ps-0">
                          <a className="file_name text-muted fw-bold" data-dz-name="">
                            {file.path}
                          </a>
                          <p className="mb-0 file_name" data-dz-size="">
                            <strong>{(file.size / 1024 / 1024).toFixed(2)}</strong>MB
                          </p>
                        </div>
                        <div className="col-auto">
                          <a
                            onClick={() => cancelFileUpload(index)}
                            className="btn btn-link btn-lg text-muted"
                            data-dz-remove=""
                          >
                            <i className="mdi mdi-close"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mb-4 text-center">
              <button onClick={handleFileUploads} className="btn btn-primary btn-lg w-100">
                {t("Wyślij")}
              </button>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  ) : (
    <></>
  );
}
