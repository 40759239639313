import { useContext } from "react";
import axios from "axios";
import { API_URL } from "../config";
import { getHeaderOptions } from "../lib/request-helper";
import { ShowLoaderContext } from "./show-loader-context";
import { CarGearBox } from "../types/car";
import { FormInputCarGearBox } from "../types/forms";

export default function useCarGearBox() {
  const { setShowLoader } = useContext(ShowLoaderContext);

  const listing = async (
    page: number,
    perPage: number,
    search: string = "",
    sortField: string | null = null,
    sortDirection: string | null = null
  ): Promise<any> => {
    setShowLoader(true);
    try {
      const result = await axios.get(
        `${API_URL}/car/gear/box?page=${page}&perPage=${perPage}&search=${search}&sortProp=${sortField}&sortDir=${sortDirection}`,
        getHeaderOptions()
      );
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const listingSimpleCarGearBoxes = async (): Promise<any> => {
    setShowLoader(true);
    try {
      const result = await axios.get(`${API_URL}/car/gear/box/simple`, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const getById = async (id: string): Promise<CarGearBox> => {
    setShowLoader(true);
    try {
      const result = await axios.get(`${API_URL}/car/gear/box/${id}`, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const createCarGearBox = async (inputData: FormInputCarGearBox): Promise<CarGearBox> => {
    setShowLoader(true);
    try {
      const result = await axios.post(`${API_URL}/car/gear/box`, inputData, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const updateCarGearBox = async (id: string, inputData: FormInputCarGearBox): Promise<CarGearBox> => {
    setShowLoader(true);
    try {
      const result = await axios.put(`${API_URL}/car/gear/box/${id}`, inputData, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const forceDeleteCarGearBox = async (id: string): Promise<CarGearBox> => {
    setShowLoader(true);
    try {
      const result = await axios.delete(`${API_URL}/car/gear/box/${id}`, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  return {
    listing,
    listingSimpleCarGearBoxes,
    getById,
    createCarGearBox,
    updateCarGearBox,
    forceDeleteCarGearBox,
  };
}
