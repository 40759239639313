import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { ToastsContext } from "../../hooks/toasts-context";
import { generate } from "../../lib/random-id-lib";
import { Car, CarBrand, CarCreateResponse, CarGearBox, CarModel, CarType, SocketCarMessage } from "../../types/car";
import useCar from "../../hooks/use-car";
import { FormInputCar } from "../../types/forms";
import { Client } from "../../types/client";
import useCarType from "../../hooks/use-car-type";
import useCarBrand from "../../hooks/use-car-brand";
import useCarModel from "../../hooks/use-car-model";
import useCarGearBox from "../../hooks/use-car-gear-box";
import useClient from "../../hooks/use-client";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { API_URL } from "../../config";
import { getHeaderOptions } from "../../lib/request-helper";
import { socket } from "../../hooks/socket-context";
import moment from "moment";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { UserContext } from "../../hooks/user-context";
import CarFilesList from "../car-files-list";

interface CarFormProps {
  car?: Car | null;
  id?: string | null;
}

export default function CarDetails({ id = null, car = null }: CarFormProps) {
  const { t } = useTranslation();
  const { createCar, updateCar } = useCar();
  const { listingSimpleCarTypes } = useCarType();
  const { listingSimpleCarBrands } = useCarBrand();
  const { listingSimpleCarModels } = useCarModel();
  const { listingSimpleCarGearBoxes } = useCarGearBox();
  const { listingSimpleClients } = useClient();
  const navigate = useNavigate();
  const {
    setValue,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<FormInputCar>();
  const { toasts, setToasts } = useContext(ToastsContext);
  const [carTypes, setCarTypes] = useState<Array<CarType>>([]);
  const [carTypesOptions, setCarTypesOptions] = useState<Array<CarType>>([]);
  const [carBrands, setCarBrands] = useState<Array<CarBrand>>([]);
  const [carBrandsOptions, setCarBrandsOptions] = useState<Array<CarBrand>>([]);
  const [carModels, setCarModels] = useState<Array<CarModel>>([]);
  const [carModelsOptions, setCarModelsOptions] = useState<Array<CarModel>>([]);
  const [filteredCarModels, setFilteredCarModels] = useState<Array<CarModel>>([]);
  const [carGearBoxes, setCarGearBoxes] = useState<Array<CarGearBox>>([]);
  const [carGearBoxesOptions, setCarGearBoxesOptions] = useState<Array<CarGearBox>>([]);
  const [clients, setClients] = useState<Array<Client>>([]);
  const [clientsOptions, setClientsOptions] = useState<Array<Client>>([]);
  const [years, setYears] = useState<Array<number>>([]);
  const [yearsOptions, setYearsOptions] = useState<Array<any>>([]);
  const [isLoadingClients, setIsLoadingClients] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const { user } = useContext(UserContext);

  const [filesBeforeUpload, setFilesBeforeUpload] = useState<Array<any>>([]);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    disabled: false,
    onDrop: (acceptedFiles) => {
      setFilesBeforeUpload([
        ...filesBeforeUpload.concat(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
              uploading: true,
              currentUploading: false,
            })
          )
        ),
      ]);
    },
  });

  React.useEffect(() => {
    console.log("errors ---", errors, "values", getValues());
  }, [errors]);

  React.useEffect(() => {
    const yrs = [];
    const maxYear = parseFloat(moment().format("YYYY"));
    for (let i = 1990; i <= maxYear; i++) {
      yrs.push(i);
    }
    setYears(yrs);
    setYearsOptions(yrs.map((it) => ({ year: it.toString() })));
    (async () => {
      try {
        const rows = await listingSimpleClients();
        setClients(rows);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  React.useEffect(() => {
    register("company", { value: "", required: true });
    register("year", { value: null, required: true });
    register("carType", { value: "", required: true });
    register("carBrand", { value: "", required: true });
    register("carModel", { value: "", required: true });
    register("carGearBox", { value: "", required: true });

    setCompanyByPartner();
  }, []);

  React.useEffect(() => {
    if (car !== undefined && car !== null) {
      setValue("company", car?.company!!);
    }
  }, [clients]);

  React.useEffect(() => {
    (async () => {
      try {
        const rows = await listingSimpleCarTypes();
        setCarTypes(rows);
        setCarTypesOptions(rows);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  React.useEffect(() => {
    setValue("carType", car?.carType!!);
  }, [carTypes]);

  React.useEffect(() => {
    (async () => {
      try {
        const rows = await listingSimpleCarBrands();
        setCarBrands(rows);
        setCarBrandsOptions(rows);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  React.useEffect(() => {
    setValue("carBrand", car?.carBrand!!);
  }, [carBrands]);

  const setCompanyByPartner = () => {
    console.log("setCompanyByPartner --- user--- ", user);
    if (user !== undefined && user !== null) {
      if (user.positionName === "Partner") {
        setValue("company", user.client);
      }
    }
  };

  React.useEffect(() => {
    setCompanyByPartner();
  }, [user]);

  React.useEffect(() => {
    (async () => {
      try {
        const rows = await listingSimpleCarModels();
        setCarModels(rows);
        setCarModelsOptions([]);

        if (car !== undefined && car !== null) {
          const newValue: string = car.CarBrand!!.id!!;
          const res = rows.filter((it: any) => it.carBrand === newValue);
          setFilteredCarModels([...res]);
          setCarModelsOptions([...res]);
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  React.useEffect(() => {
    setValue("carModel", car?.carModel!!);
  }, [carModels]);

  React.useEffect(() => {
    (async () => {
      try {
        const rows = await listingSimpleCarGearBoxes();
        setCarGearBoxes(rows);
        setCarGearBoxesOptions(rows);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  React.useEffect(() => {
    if (car !== undefined && car !== null) {
      (async () => {
        const rows = await listingSimpleClients(car.Company?.companyName);
        rows.forEach((row: any) => {
          row.title = `${row.companyName}, ${row.companyNip}`;
        });
        setClientsOptions(rows);
      })();
    }
  }, []);

  React.useEffect(() => {
    setValue("carGearBox", car?.carGearBox!!);
  }, [carGearBoxes]);

  React.useEffect(() => {
    if (car !== undefined && car !== null) {
      setFilteredCarModels([...carModels.filter((it) => it.carBrand === car.carBrand!!)]);
      setValue("carModel", car?.carModel!!);
    }
  }, [carBrands, carModels]);

  React.useEffect(() => {
    if (car !== undefined && car !== null) {
      const keys: Array<string> = Object.keys(getValues() as FormInputCar);
      for (let k of keys) {
        // @ts-ignore
        setValue(k as keyof FormInputCar, car[k as keyof Car]);
      }
    }
  }, []);

  const uploadSingleFile = async (carId: string, index: number, filesCounter: number) => {
    const headers: any = getHeaderOptions();
    headers.onUploadProgress = (progressEvent: any) => {
      console.log(progressEvent.loaded);
      console.log(index, filesCounter);
      console.log(progressEvent);
      let value = (progressEvent.loaded * 100) / progressEvent.total / filesCounter + (100 / filesCounter) * index;

      if (index + 1 === filesCounter) {
        if (progressEvent.loaded === progressEvent.total) {
          value = 100;
        }
      }

      const content: SocketCarMessage = {
        car: carId,
        progress: value,
        room: "all",
      };
      socket.emit("message", content);
    };

    const formData = new FormData();
    formData.set("file", filesBeforeUpload[index], filesBeforeUpload[index].name);
    await axios.post(`${API_URL}/car/${carId}/upload`, formData, headers);
  };

  return (
    <div className="row mb-5 car-details-view">
      <div className="col-12">
        <div>
          <div className="card shadow">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h4 className="header-title">{t("Szczegóły pojazdu")}</h4>
              </div>
              <div className="row">
                {user !== undefined && user !== null && user.positionName !== "Partner" ? (
                  <div className="col-12 col-md-6 mb-4">
                    <div className="form-group position-relative">
                      <label className="form-label">{t("Firma")}</label>
                      <div>{`${car!!.Company?.companyName}, ${car!!.Company?.companyNip}`}</div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                <div className="col-12 col-md-6 mb-4">
                  <div className="form-group position-relative">
                    <label className="form-label">{t("Typ pojazdu")}</label>
                    <div>{car!!.CarType?.title}</div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6 mb-4">
                  <div className="form-group position-relative">
                    <label className="form-label">{t("Marka")}</label>
                    <div>{car!!.CarBrand?.title}</div>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group position-relative">
                    <label className="form-label">{t("Model")}</label>
                    <div>{car!!.CarModel?.title}</div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6 mb-4">
                  <div className="form-group position-relative">
                    <label className="form-label">{t("Rok")}</label>
                    <div>{car!!.year!!.toString()}</div>
                  </div>
                </div>
                <div className="col-12 col-md-6 mb-4">
                  <div className="form-group position-relative">
                    <label className="form-label">{t("Skrzynia biegów")}</label>
                    <div>{car!!.CarGearBox?.title}</div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6 mb-4">
                  <div className="form-group position-relative">
                    <label className="form-label">{t("Moc")}</label>
                    <div>{car!!.carPower}</div>
                  </div>
                </div>
                <div className="col-12 col-md-6 mb-4">
                  <div className="form-group position-relative">
                    <label className="form-label">{t("Numer rejestracyjny")}</label>
                    <div>{car!!.registrationNumber}</div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6 mb-4">
                  <div className="form-group position-relative">
                    <label className="form-label">{t("Numer VIN")}</label>
                    <div>{car!!.vin}</div>
                  </div>
                </div>
                <div className="col-12 col-md-6 mb-4">
                  <div className="form-group position-relative">
                    <label className="form-label">{t("Euro")}</label>
                    <div>{car!!.euro}</div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6 mb-4">
                  <div className="form-group position-relative">
                    <label className="form-label">{t("Przebieg")}</label>
                    <div>{car!!.mileage}</div>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-12 col-md-12 mb-4">
                  <div className={"row mb-4"}>
                    <div className="col-12 col-md-4">
                      <div className="form-check form-switch">
                        <input
                          readOnly={true}
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="tuning"
                          {...register("withTuning")}
                          defaultValue={""}
                        />
                        <label className="form-check-label" htmlFor="tuning">
                          <img src={"/svg/tuning.svg"} className={"icon-tuning"} />
                          {t("Tuning")}
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-check form-switch mb-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="tuningFix"
                          {...register("withTuningFix")}
                          defaultValue={""}
                        />
                        <label className="form-check-label" htmlFor="tuningFix">
                          {t("Poprawka")}
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-check form-switch mb-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="tuningRevert"
                          {...register("withTuningRevert")}
                          defaultValue={""}
                        />
                        <label className="form-check-label" htmlFor="tuningRevert">
                          {t("Przywrócenie")}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className={"row mb-4"}>
                    <div className="col-12 col-md-4">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="ograniczenia"
                          {...register("withLimitations")}
                          defaultValue={""}
                        />
                        <label className="form-check-label" htmlFor="ograniczenia">
                          <img src={"/svg/engine.svg"} className={"icon-tuning"} />
                          {t("Wyłączenie ograniczeń")}
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-check form-switch mb-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="ograniczeniaFix"
                          {...register("withLimitationsFix")}
                          defaultValue={""}
                        />
                        <label className="form-check-label" htmlFor="ograniczeniaFix">
                          {t("Poprawka")}
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-check form-switch mb-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="ograniczeniaRevert"
                          {...register("withLimitationsRevert")}
                          defaultValue={""}
                        />
                        <label className="form-check-label" htmlFor="ograniczeniaRevert">
                          {t("Przywrócenie")}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className={"row mb-4"}>
                    <div className="col-12 col-md-4">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="adblue"
                          {...register("withAdBlue")}
                          defaultValue={""}
                        />
                        <label className="form-check-label" htmlFor="adblue">
                          <img src={"/svg/adblue.svg"} className={"icon-tuning"} />
                          {t("Zmniejszenie pobierania AdBLue")}
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-check form-switch mb-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="adblueFix"
                          {...register("withAdBlueFix")}
                          defaultValue={""}
                        />
                        <label className="form-check-label" htmlFor="adblueFix">
                          {t("Poprawka")}
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-check form-switch mb-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="adblueRevert"
                          {...register("withAdBlueRevert")}
                          defaultValue={""}
                        />
                        <label className="form-check-label" htmlFor="adblueRevert">
                          {t("Przywrócenie")}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className={"row mt-5"}>
                    <div className="col-md-12">
                      <CarFilesList car={car!!} withUpload={false} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
