import React from 'react';
import {Car} from '../../types/car';
import styled from '@emotion/styled';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';

const IconStyled = styled.i`
  font-size: 24px;
  margin: 0 .25rem;
  color: rgba(var(--bs-success-rgb));
`;

interface CarModificationsFixRevertTooltipProps {
  car: Car
}

const CarModificationsFixRevertTooltip = ({car}: CarModificationsFixRevertTooltipProps) => {
  if (car.withLimitationsFix || car.withAdBlueFix || car.withTuningFix
    || car.withLimitationsRevert || car.withAdBlueRevert || car.withTuningRevert) {
    return (
      <OverlayTrigger overlay={
        <Tooltip>
          <div className={'text-left'}>
            <div>{car.withTuningFix ? 'Poprawka Tuning' : ''}</div>
            <div>{car.withLimitationsFix ? 'Poprawka ograniczeń' : ''}</div>
            <div>{car.withAdBlueFix ? 'Poprawka AdBlue' : ''}</div>
            <div>{car.withTuningRevert ? 'Przywrócenie ORY Tuning' : ''}</div>
            <div>{car.withLimitationsRevert ? 'Przywrócenie ORY ograniczeń' : ''}</div>
            <div>{car.withAdBlueRevert ? 'Przywrócenie ORY AdBlue' : ''}</div>
          </div>
        </Tooltip>
      }>
       <span>
         <IconStyled className={'mdi mdi-information'}></IconStyled>
       </span>
      </OverlayTrigger>
    )
  } else {
    return <></>
  }
}

export default CarModificationsFixRevertTooltip;
