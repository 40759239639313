import { useContext } from "react";
import axios from "axios";
import { API_URL } from "../config";
import { getHeaderOptions } from "../lib/request-helper";
import { ShowLoaderContext } from "./show-loader-context";
import { CarNote } from "../types/car";
import { FormInputCarNote } from "../types/forms";

export default function useCarNotes() {
  const { setShowLoader } = useContext(ShowLoaderContext);

  const listing = async (carId: string): Promise<any> => {
    setShowLoader(true);
    try {
      const result = await axios.get(`${API_URL}/car/note/${carId}`, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const createCarNote = async (carId: string, inputData: FormInputCarNote): Promise<CarNote> => {
    setShowLoader(true);
    try {
      const result = await axios.post(`${API_URL}/car/note/${carId}`, inputData, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  return {
    listing,
    createCarNote,
  };
}
