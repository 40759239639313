import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { Client } from "../../types/client";
import useClient from "../../hooks/use-client";
import { FormInputClient } from "../../types/forms";
import { ToastsContext } from "../../hooks/toasts-context";
import { generate } from "../../lib/random-id-lib";
import { Employee } from "../../types/employee";
import useEmployee from "../../hooks/use-employee";

interface ClientFormProps {
  client?: Client | null;
  id?: string | null;
  employeeId?: string | null;
  employee?: Employee | null;
  asPartner?: boolean;
}

export default function ClientForm({
  id = null,
  client = null,
  employeeId = null,
  employee = null,
  asPartner = false,
}: ClientFormProps) {
  const { t } = useTranslation();
  const { createClient, updateClient, apiGusLogin } = useClient();
  const navigate = useNavigate();
  const {
    setValue,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<FormInputClient>();
  const { toasts, setToasts } = useContext(ToastsContext);
  const { assignClient } = useEmployee();

  React.useEffect(() => {
    if (client !== undefined && client !== null) {
      const keys: Array<string> = Object.keys(getValues() as FormInputClient);
      for (let k of keys) {
        // @ts-ignore
        setValue(k as keyof FormInputClient, client[k as keyof Client]);
      }
    }

    if (employee !== undefined && employee !== null) {
      setValue("email", employee.email);
    }
  }, []);

  const searchGus = async () => {
    try {
      const result = await apiGusLogin(getValues("companyNip")!!);
      const j = JSON.parse(result.result);
      let elements = j.elements[0].elements;
      elements = elements[0].elements;

      const eName = elements.find((it: any) => it.name === "Nazwa");
      if (eName) {
        setValue("companyName", eName.elements[0].text);
        setValue("companyNameShort", eName.elements[0].text);
      }
      const eCity = elements.find((it: any) => it.name === "Miejscowosc");
      if (eCity) {
        setValue("city", eCity.elements[0].text);
      }
      const eZipCode = elements.find((it: any) => it.name === "KodPocztowy");
      if (eZipCode) {
        setValue("zipCode", eZipCode.elements[0].text);
      }
      const eStreet = elements.find((it: any) => it.name === "Ulica");
      const eHomeNumber = elements.find((it: any) => it.name === "NrNieruchomosci");
      const eHomeNumber2 = elements.find((it: any) => it.name === "NrLokalu");
      if (eStreet && eHomeNumber && eHomeNumber2) {
        setValue(
          "street",
          eStreet.elements[0].text + " " + eHomeNumber.elements[0].text + "/" + eHomeNumber2.elements[0].text
        );
      } else if (eStreet && eHomeNumber) {
        setValue("street", eStreet.elements[0].text + " " + eHomeNumber.elements[0].text);
      } else if (eStreet) {
        setValue("street", eStreet.elements[0].text);
      }

      console.log(elements);
    } catch (e: any) {
      console.log(e);
    }
  };

  const handleLogin = async (data: any) => {
    console.log("data", data);
    if (id !== undefined && id !== null) {
      try {
        await updateClient(id, data);
        toasts.push({ title: t("Sukces"), content: t("Dane zapisane"), show: true, id: generate(32), type: "success" });
        setToasts([...toasts]);
        if (!asPartner) {
          navigate("/clients");
        }
      } catch (e) {
        console.log(e);
        toasts.push({
          title: t("Błąd"),
          content: t("Nie udało się zapisać"),
          show: true,
          id: generate(32),
          type: "danger",
        });
        setToasts([...toasts]);
      }
    } else {
      try {
        const res = await createClient(data);
        toasts.push({ title: t("Sukces"), content: t("Dane zapisane"), show: true, id: generate(32), type: "success" });
        setToasts([...toasts]);
        if (employee !== undefined && employee !== null) {
          await assignClient(employeeId!!, res.id!!);
          navigate(`/employee/edit/${employeeId}`);
        } else {
          navigate("/clients");
        }
      } catch (e) {
        console.log(e);
        toasts.push({
          title: t("Błąd"),
          content: t("Nie udało się zapisać"),
          show: true,
          id: generate(32),
          type: "danger",
        });
        setToasts([...toasts]);
      }
    }
  };

  return (
    <div className="row mb-5">
      <div className="col-12">
        <form onSubmit={handleSubmit(handleLogin)}>
          <div className="card shadow">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-4">
                {asPartner ? (
                  <h4 className="header-title">{t("Edytuj dane")}</h4>
                ) : (
                  <h4 className="header-title">
                    {t(id !== undefined && id !== null ? "Edytuj klienta" : "Dodaj klienta")}
                    {employeeId !== undefined && employeeId !== null
                      ? ` (${t("for partner")}: ${employee !== undefined && employee !== null ? employee.email : ""}) `
                      : ""}
                  </h4>
                )}
              </div>

              <div className="row">
                <div className="col-12 col-md-6 mb-4">
                  <div className="input-group position-relative">
                    <input
                      className={"form-control" + (errors && errors.companyNip !== undefined ? " has-error" : "")}
                      placeholder={t("NIP")}
                      {...register("companyNip", { required: true })}
                      defaultValue={""}
                    />
                    <button className="btn btn-secondary" type="button" onClick={searchGus}>
                      {t("Pobierz dane z GUS")}
                    </button>
                    {errors && errors.companyNip !== undefined ? (
                      <span className={"error-message"}>{t(errors.companyNip?.type)}</span>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6 mb-4">
                  <div className="form-group position-relative">
                    <label htmlFor="company_name" className="form-label">
                      {t("Nazwa firmy")}
                    </label>
                    <input
                      className={"form-control" + (errors && errors.companyName !== undefined ? " has-error" : "")}
                      id="company_name"
                      {...register("companyName", { required: true })}
                      defaultValue={""}
                    />
                    {errors && errors.companyName !== undefined ? (
                      <span className={"error-message"}>{t(errors.companyName?.type)}</span>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="col-12 col-md-6 mb-4">
                  <div className="form-group position-relative">
                    <label htmlFor="company_name_short" className="form-label">
                      {t("Skrócona nazwa firmy")}
                    </label>
                    <input
                      className={"form-control" + (errors && errors.companyNameShort !== undefined ? " has-error" : "")}
                      id="company_name_short"
                      {...register("companyNameShort")}
                      defaultValue={""}
                    />
                    {errors && errors.companyNameShort !== undefined ? (
                      <span className={"error-message"}>{t(errors.companyNameShort?.type)}</span>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-4 mb-4">
                  <div className="form-group position-relative">
                    <label htmlFor="street" className="form-label">
                      {t("Ulica i numer")}
                    </label>
                    <input
                      className={"form-control" + (errors && errors.street !== undefined ? " has-error" : "")}
                      id="street"
                      {...register("street")}
                      defaultValue={""}
                    />
                    {errors && errors.street !== undefined ? (
                      <span className={"error-message"}>{t(errors.street?.type)}</span>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="col-12 col-md-2 mb-4">
                  <div className="form-group position-relative">
                    <label htmlFor="code" className="form-label">
                      {t("Kod pocztowy")}
                    </label>
                    <input
                      className={"form-control" + (errors && errors.zipCode !== undefined ? " has-error" : "")}
                      id="code"
                      {...register("zipCode")}
                      defaultValue={""}
                    />
                    {errors && errors.zipCode !== undefined ? (
                      <span className={"error-message"}>{t(errors.zipCode?.type)}</span>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="col-12 col-md-3 mb-4">
                  <div className="form-group position-relative">
                    <label htmlFor="compatownny_name_short" className="form-label">
                      {t("Miejscowość")}
                    </label>
                    <input
                      className={"form-control" + (errors && errors.city !== undefined ? " has-error" : "")}
                      id="town"
                      {...register("city")}
                      defaultValue={""}
                    />
                    {errors && errors.city !== undefined ? (
                      <span className={"error-message"}>{t(errors.city?.type)}</span>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="col-12 col-md-3 mb-4">
                  <div className="form-group position-relative">
                    <label htmlFor="country" className="form-label">
                      {t("Kraj")}
                    </label>
                    <input
                      className={"form-control" + (errors && errors.country !== undefined ? " has-error" : "")}
                      id="country"
                      {...register("country")}
                      defaultValue={""}
                    />
                    {errors && errors.country !== undefined ? (
                      <span className={"error-message"}>{t(errors.country?.type)}</span>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6 mb-4">
                  <div className="form-group position-relative">
                    <label htmlFor="email" className="form-label">
                      {t("Email")}
                    </label>
                    <input
                      className={"form-control" + (errors && errors.email !== undefined ? " has-error" : "")}
                      id="email"
                      {...register("email")}
                      defaultValue={""}
                    />
                    {errors && errors.email !== undefined ? (
                      <span className={"error-message"}>{t(errors.email?.type)}</span>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="col-12 col-md-6 mb-4">
                  <div className="form-group position-relative">
                    <label htmlFor="phone" className="form-label">
                      {t("Telefon")}
                    </label>
                    <input
                      className={"form-control" + (errors && errors.phone !== undefined ? " has-error" : "")}
                      id="phone"
                      {...register("phone")}
                      defaultValue={""}
                    />
                    {errors && errors.phone !== undefined ? (
                      <span className={"error-message"}>{t(errors.phone?.type)}</span>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 mb-4">
                  <label htmlFor="info" className="form-label">
                    {t("Dodatkowe informacje")}
                  </label>
                  <textarea
                    className={"form-control" + (errors && errors.information !== undefined ? " has-error" : "")}
                    id="info"
                    {...register("information")}
                    defaultValue={""}
                  ></textarea>
                  {errors && errors.information !== undefined ? (
                    <span className={"error-message"}>{t(errors.information?.type)}</span>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div className="card-footer p-4 text-muted">
              <div className="row">
                <div className="col-12 text-end">
                  {!asPartner ? (
                    <Link className="btn btn-outline-primary" to={"/clients"}>
                      {t("Anuluj")}
                    </Link>
                  ) : (
                    <></>
                  )}
                  {id !== undefined && id !== null ? (
                    <button className="btn btn-outline-primary">{t("Zapisz")}</button>
                  ) : (
                    <button className="btn btn-outline-primary">{t("Dodaj")}</button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
