import { createContext } from "react";
import { Breadcrumb } from "../types/breadcrumb";

export interface Layout {
  sideMenu: string;
  expanded: Array<string>;
  breadcrumbs: Array<Breadcrumb>;
}

export interface LayoutContextData {
  layout: Layout;
  setLayout: (layout: Layout | CallableFunction) => void;
}

const layoutContextDefaultValue: LayoutContextData = {
  layout: {
    sideMenu: "",
    expanded: [],
    breadcrumbs: [],
  },
  setLayout: () => null,
};

export const LayoutContext = createContext<LayoutContextData>(layoutContextDefaultValue);
