import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "./../hooks/use-auth";
import { useTranslation } from "react-i18next";
import { UserContext } from "../hooks/user-context";
import { useForm } from "react-hook-form";
import { FormInputLogin } from "../types/forms";

export default function Login() {
  const { user, setUserContext } = useContext(UserContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { loginUser, error, setError }: any = useAuth();
  const {
    setValue,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<FormInputLogin>();

  console.log("data", getValues());

  React.useEffect(() => {
    if (user !== undefined && user !== null) {
      navigate("/home");
    }
  }, []);

  const handleLogin = async (data: any) => {
    console.log("data", data);

    try {
      await loginUser(data);
      await setUserContext();
      navigate("/home");
    } catch (e) {
      console.log("login-error", e);
    }
  };

  return (
    <div className="wrapper">
      <div className="container">
        <div className="row mb-5 d-flex justify-content-center">
          <div className="col-12 col-md-6 col-lg-5">
            <div className="card shadow mt-5">
              <div className="bg-light text-center py-5">
                <img src={"/svg/favicon.svg"} className={"w-25 mb-4"} />
                <h4 className="header-title">{t("Zaloguj się do Chip Tunig Menagera")}</h4>
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit(handleLogin)}>
                  <div className="row">
                    <div className="col-12 mb-4">
                      <div className="position-relative">
                        <label className="form-label">{t("Nazwa / E-mail")}</label>
                        <input
                          className="form-control"
                          id="loginUsername"
                          type={"text"}
                          {...register("email", { required: true })}
                          onChange={(e) => setValue("email", e.target.value)}
                          defaultValue={""}
                        />
                        {errors && errors.email !== undefined ? (
                          <span className={"error-message"}>{t(errors.email?.type)}</span>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <div className="col-12 mb-4">
                      <div className="position-relative">
                        <label className="form-label d-flex justify-content-between">
                          <span>{t("Hasło")}</span>
                          <Link to={"/forget-password"}>{t("Zapomniałeś hasła?")}</Link>
                        </label>
                        <input
                          className="form-control"
                          id="loginPassword"
                          {...register("password", { required: true })}
                          type={"password"}
                          onChange={(e) => {
                            setError(null);
                            setValue("password", e.target.value);
                          }}
                          defaultValue={""}
                        />
                        {errors && errors.password !== undefined ? (
                          <span className={"error-message"}>{t(errors.password?.type)}</span>
                        ) : (
                          <></>
                        )}
                        {error ? <span className={"error-message"}>{t("Podaje poprawne dane logowania")}</span> : <></>}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 mb-4 text-center">
                      <button className="btn btn-primary btn-lg w-100" type={"submit"}>
                        {t("Wyślij")}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
