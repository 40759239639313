import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { ToastsContext } from "../../hooks/toasts-context";
import { generate } from "../../lib/random-id-lib";
import { CarGearBox } from "../../types/car";
import useCarGearBox from "../../hooks/use-car-gear-box";
import { FormInputCarGearBox } from "../../types/forms";

interface CarGearBoxFormProps {
  carGearBox?: CarGearBox | null;
  id?: string | null;
}

export default function CarGearBoxForm({ id = null, carGearBox = null }: CarGearBoxFormProps) {
  const { t } = useTranslation();
  const { createCarGearBox, updateCarGearBox } = useCarGearBox();
  const navigate = useNavigate();
  const {
    setValue,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<FormInputCarGearBox>();
  const { toasts, setToasts } = useContext(ToastsContext);

  React.useEffect(() => {
    if (carGearBox !== undefined && carGearBox !== null) {
      const keys: Array<string> = Object.keys(getValues() as FormInputCarGearBox);
      for (let k of keys) {
        // @ts-ignore
        setValue(k as keyof FormInputCarGearBox, carGearBox[k as keyof CarGearBox]);
      }
    }
  }, []);

  const handleLogin = async (data: any) => {
    console.log("data", data);
    if (id !== undefined && id !== null) {
      try {
        await updateCarGearBox(id, data);
        toasts.push({ title: t("Sukces"), content: t("Dane zapisane"), show: true, id: generate(32), type: "success" });
        setToasts([...toasts]);
        navigate("/settings/car/gear/box");
      } catch (e) {
        console.log(e);
        toasts.push({
          title: t("Błąd"),
          content: t("Nie udało się zapisać"),
          show: true,
          id: generate(32),
          type: "danger",
        });
        setToasts([...toasts]);
      }
    } else {
      try {
        await createCarGearBox(data);
        toasts.push({ title: t("Sukces"), content: t("Dane zapisane"), show: true, id: generate(32), type: "success" });
        setToasts([...toasts]);
        navigate("/settings/car/gear/box");
      } catch (e) {
        console.log(e);
        toasts.push({
          title: t("Błąd"),
          content: t("Nie udało się zapisać"),
          show: true,
          id: generate(32),
          type: "danger",
        });
        setToasts([...toasts]);
      }
    }
  };

  return (
    <div className="row mb-5">
      <div className="col-12">
        <form onSubmit={handleSubmit(handleLogin)}>
          <div className="card shadow">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h4 className="header-title">
                  {t(id !== undefined && id !== null ? "Edytuj skrzynię biegów" : "Dodaj skrzynię biegów")}
                </h4>
              </div>

              <div className="row">
                <div className="col-12 col-md-6 mb-4">
                  <div className="form-group position-relative">
                    <label htmlFor="user_name" className="form-label">
                      {t("Nazwa")}
                    </label>
                    <input
                      className={"form-control" + (errors && errors.title !== undefined ? " has-error" : "")}
                      id="title"
                      {...register("title", { required: true })}
                      defaultValue={""}
                    />
                    {errors && errors.title !== undefined ? (
                      <span className={"error-message"}>{t(errors.title?.type)}</span>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer p-4 text-muted">
              <div className="row">
                <div className="col-12 text-end">
                  <Link className="btn btn-outline-primary" to={"/settings/car/gear/box"}>
                    {t("Anuluj")}
                  </Link>
                  {id !== undefined && id !== null ? (
                    <button className="btn btn-outline-primary">{t("Zapisz")}</button>
                  ) : (
                    <button className="btn btn-outline-primary">{t("Dodaj")}</button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
