import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { LayoutContext } from "../../hooks/layout-context";
import CarsListing from "../../components/cars-listing";

export default function CarsList() {
  const { t } = useTranslation();
  const { layout, setLayout } = useContext(LayoutContext);

  React.useEffect(() => {
    layout.sideMenu = "cars";
    layout.breadcrumbs = [{ path: null, label: "Pojazdy" }];
    setLayout({ ...layout });
  }, []);

  return <CarsListing asTuning={false} />;
}
