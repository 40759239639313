import axios from "axios";
import {API_URL} from "../config";
import {getHeaderOptions, TOKEN_KEY} from "../lib/request-helper";

export default function useLogout() {
  const logoutUser = async () => {
    try {
      await axios.post(`${API_URL}/auth/logout`, {}, getHeaderOptions());
      localStorage.removeItem(TOKEN_KEY);
      return true;
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  return {
    logoutUser,
  };
}
