// const API_URL = "http://localhost:7000";
// const SOCKET_URL = 'http://localhost:6059'

const API_URL = "https://api.techtir.pl";
const SOCKET_URL = "https://socket.techtir.pl";

const apiKeyGus = "bdb8d07b2924455b8917";
const apiUrlGus = "https://wyszukiwarkaregon.stat.gov.pl/wsBIR/UslugaBIRzewnPubl.svc";
const apiWsdlGus = "https://wyszukiwarkaregon.stat.gov.pl/wsBIR/wsdl/UslugaBIRzewnPubl-ver11-prod.wsdl";

export { API_URL, SOCKET_URL, apiUrlGus, apiKeyGus, apiWsdlGus };
