import React, { useContext, Suspense } from "react";
import { UserContext } from "../../hooks/user-context";
import { useTranslation } from "react-i18next";
import { ShowLoaderContext } from "../../hooks/show-loader-context";
import useProfile from "../../hooks/use-profile";
import SideMenu from "./side-menu";
import Breadcrumbs from "./breadcrumbs";
import { Toast, ToastContainer } from "react-bootstrap";
import { ToastsContext } from "../../hooks/toasts-context";
import { ToastItem } from "../../types/toast";
import { Link } from "react-router-dom";

export default function Template({ children }: any) {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { user } = useContext(UserContext);
  const { showLoader } = useContext(ShowLoaderContext);
  const { changeLanguage } = useProfile();
  const [drawerWidth, setDrawerWidth] = React.useState(260);
  const { toasts, setToasts } = useContext(ToastsContext);

  React.useEffect(() => {
    try {
      const tmp = localStorage.getItem("drawerWidth");
      if (tmp !== undefined && tmp !== null) {
        setDrawerWidth(parseFloat(tmp));
      }
    } catch (e: any) {
      console.log(e);
    }
  }, []);

  const changeDrawerWidth = (value: number) => {
    setDrawerWidth(value);
    localStorage.setItem("drawerWidth", value.toString());
  };

  const onToastClose = (toast: ToastItem) => {
    const index = toasts.findIndex((it) => it.id === toast.id);
    if (index !== -1) {
      toasts[index].show = false;
      setToasts([...toasts]);
    }
  };

  return (
    <Suspense fallback="loading">
      <>
        <div aria-live="polite" aria-atomic="true" className="position-relative">
          <ToastContainer position="top-end" className={"toasts-container"}>
            {toasts.map((it: ToastItem, index: number) => (
              <Toast
                key={`ToastItem${index}`}
                autohide={true}
                delay={it.duration !== undefined && it.duration !== null ? it.duration : 3000}
                onClose={() => onToastClose(it)}
                show={it.show}
              >
                <Toast.Header>
                  <strong
                    className={"me-auto" + (it.type !== undefined && it.type !== null ? " " + "text-" + it.type : "")}
                  >
                    {it.title}
                  </strong>
                  {/*<small className="text-muted">just now</small>*/}
                </Toast.Header>
                <Toast.Body>{it.content}</Toast.Body>
              </Toast>
            ))}
          </ToastContainer>
        </div>
        {user ? (
          <>
            <div className="wrapper">
              {user ? <SideMenu drawerWidth={drawerWidth} /> : <></>}
              <div className={`content-page${drawerWidth === 60 ? " larger" : ""}`}>
                <nav className={`navbar-custom${drawerWidth === 60 ? " larger" : ""}`}>
                  <ul className="list-unstyled topbar-menu float-end mb-0">
                    <li className="notification-list">
                      <Link to={"/my-account"} className="nav-link nav-user arrow-none me-0">
                        <span className="account-user-avatar">
                          <img src="/svg/avatar-person.svg" />
                        </span>
                        <span>
                          <span className="account-user-name">{user ? user.firstName : ""}</span>
                          <span className="account-position">
                            {user ? t(user.positionName === "Tuning" ? "Technik" : user.positionName) : ""}
                          </span>
                        </span>
                      </Link>
                    </li>
                  </ul>
                  <button className="btn-menu-mobile" onClick={() => changeDrawerWidth(drawerWidth === 260 ? 60 : 260)}>
                    <i className="mdi mdi-menu"></i>
                  </button>
                  <div className="app-search dropdown d-none d-lg-block">
                    <form>
                      <div className="input-group">
                        <input type="text" className="form-control" placeholder={t("Szukaj") + "..."} id="top-search" />
                        <span className="mdi mdi-magnify search-icon"></span>
                        <button className="input-group-text btn-primary" type="submit">
                          {t("Szukaj")}
                        </button>
                      </div>
                    </form>
                  </div>
                </nav>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12">
                      <div className="page-title-box">
                        <Breadcrumbs />
                        <h4 className="page-title">Dashboard</h4>
                      </div>
                    </div>
                  </div>

                  {children}
                </div>
              </div>
            </div>
          </>
        ) : (
          <>{children}</>
        )}
      </>
    </Suspense>
  );
}
