import React, { useContext } from "react";
import { LayoutContext } from "../hooks/layout-context";
import { useTranslation } from "react-i18next";

export default function NotFound() {
  const { layout, setLayout } = useContext(LayoutContext);
  const { t } = useTranslation();

  React.useEffect(() => {
    setLayout({
      ...layout,
      sideMenu: "",
    });
  }, []);

  return (
    <div>
      <h1>404</h1>
      <div>{t("Nie znaleziono")}</div>
    </div>
  );
}
