import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { LayoutContext } from "../../../hooks/layout-context";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { Dropdown } from "react-bootstrap";
import { CarBrand } from "../../../types/car";
import useCarBrand from "../../../hooks/use-car-brand";
import Confirmation from "../../../components/layout/confirmation";
import { generate } from "../../../lib/random-id-lib";
import { ToastsContext } from "../../../hooks/toasts-context";

export default function CarBrandList() {
  const { t } = useTranslation();
  const { layout, setLayout } = useContext(LayoutContext);
  const [rows, setRows] = useState<Array<CarBrand>>([]);
  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [showTable, setShowTable] = useState(false);
  const { listing, forceDeleteCarBrand } = useCarBrand();
  const [selectedDelete, setSelectedDelete] = useState<CarBrand | null>(null);
  const [search, setSearch] = useState<string>("");
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);
  const { toasts, setToasts } = useContext(ToastsContext);

  React.useEffect(() => {
    layout.sideMenu = "settings";
    layout.breadcrumbs = [
      { label: t("Ustawienia"), path: "/settings" },
      { path: null, label: t("Marka pojazdu") },
    ];
    setLayout({ ...layout });

    (async () => {
      await loadListing(1, perPage);
    })();
  }, []);

  const columns: Array<any> = [
    {
      name: t("Nazwa"),
      selector: (row: any) => <span>{row.title}</span>,
      right: false,
      sortable: true,
      key: "title",
    },
    {
      name: "",
      selector: (row: any) => (
        <Dropdown>
          <Dropdown.Toggle as={"a"}>
            <i className="mdi mdi-dots-vertical"></i>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item as={"div"}>
              <Link to={`/settings/car/brand/edit/${row.id}`}>{t("Edytuj")}</Link>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setSelectedDelete(row)}>{t("Usuń")}</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ),
      right: true,
    },
  ];

  const handlePageChange = async (page: number) => {
    setLoading(true);
    await loadListing(page, perPage, sortField, sortDirection);
  };

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    setLoading(true);
    await loadListing(page, newPerPage, sortField, sortDirection);
    setPerPage(newPerPage);
  };

  const loadListing = async (
    page: number,
    currentPerPage: number,
    sField: string | null = null,
    sDirection: string | null = null
  ) => {
    try {
      const result = await listing(page, currentPerPage, search, sField, sDirection);
      setRows(result.data);
      setTotalRows(result.page.totalElements);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
    if (!showTable) {
      setShowTable(true);
    }
  };

  React.useEffect(() => {
    (async () => {
      await loadListing(1, perPage, sortField, sortDirection);
    })();
  }, [search]);

  const handleSort = async (column: any, direction: any) => {
    console.log("column", column, "direction", direction);
    setLoading(true);
    setSortField(column.key);
    setSortDirection(direction);
    await loadListing(1, perPage, column.key, direction);
    setLoading(false);
  };

  const deleteRow = async (id: any) => {
    console.log("deleteClient", id);
    if (selectedDelete !== undefined && selectedDelete !== null) {
      try {
        await forceDeleteCarBrand(selectedDelete.id!!);
        toasts.push({
          title: t("Sukces"),
          content: t("Rodzaj pojazdu usunięty"),
          show: true,
          id: generate(32),
          type: "success",
        });
        setToasts([...toasts]);
      } catch (e) {
        console.log(e);
        toasts.push({
          title: t("Błąd"),
          content: t("Nie udało się usunąć. Marka pojazdu jest używana."),
          show: true,
          id: generate(32),
          type: "danger",
        });
        setToasts([...toasts]);
      }

      await loadListing(1, perPage, sortField, sortDirection);
    }

    setSelectedDelete(null);
  };

  return (
    <div className="row mb-5">
      <div className="col-12">
        <div className="card shadow">
          <div className="card-body">
            <div className="row mb-4">
              <div className="col-sm-5">
                <Link to={"/settings/car/brand/add"} className="btn btn-danger mb-2">
                  <i className="mdi mdi-plus-circle me-2"></i>
                  {t("Dodaj markę pojazdu")}
                </Link>
              </div>
              <div className="col-sm-7">
                <div className="data-tables-filter text-end">
                  <label>
                    {t("Szukaj")}:
                    <input
                      type="search"
                      className="form-control form-control-sm"
                      placeholder=""
                      onChange={(e) => setSearch(e.target.value)}
                      value={search || ""}
                      name={"search"}
                    />
                  </label>
                </div>
              </div>
            </div>
            <div className="table-responsive">
              {showTable ? (
                <DataTable
                  columns={columns}
                  data={rows}
                  striped={false}
                  sortServer
                  onSort={handleSort}
                  pagination
                  paginationServer
                  paginationTotalRows={totalRows}
                  paginationComponentOptions={{
                    rowsPerPageText: t("Wyświetl marek pojazdów na stronę:"),
                    rangeSeparatorText: t("of"),
                    noRowsPerPage: false,
                    selectAllRowsItem: false,
                    selectAllRowsItemText: t("All"),
                  }}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                />
              ) : (
                <></>
              )}
              <Confirmation
                showConfirmation={selectedDelete !== null}
                onClose={() => setSelectedDelete(null)}
                onConfirm={() => deleteRow(selectedDelete!!.id)}
                content={t("Czy na pewno chcesz usunąć markę pojazdu?")}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
