import React, { useContext, useState } from "react";
import { LayoutContext } from "../../../hooks/layout-context";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import CarTypeForm from "../../../components/forms/car-type-form";
import { CarBrand } from "../../../types/car";
import useCarBrand from "../../../hooks/use-car-brand";
import CarBrandForm from "../../../components/forms/car-brand-form";

export default function CarBrandEdit() {
  const { t } = useTranslation();
  const { layout, setLayout } = useContext(LayoutContext);
  const params = useParams();
  const { getById } = useCarBrand();
  const [carBrand, setCarBrand] = useState<CarBrand | null>();

  React.useEffect(() => {
    console.log("params", params);
    if (params !== undefined && params !== null) {
      if (params.id !== undefined && params.id !== null) {
        (async () => {
          const result = await getById(params.id!!);
          setCarBrand(result);
        })();
      }
    }
  }, []);

  React.useEffect(() => {
    layout.sideMenu = "settings";
    layout.breadcrumbs = [
      { label: t("Ustawienia"), path: "/settings" },
      {
        label: t("Marka pojazdu"),
        path: "/settings/car/brand",
      },
      { path: null, label: t("Edytuj") },
    ];
    setLayout({ ...layout });
  }, []);

  return carBrand !== undefined && carBrand !== null ? (
    <CarBrandForm id={params.id || null} carBrand={carBrand} />
  ) : (
    <></>
  );
}
