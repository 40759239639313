import React, { useContext, useState } from "react";
import { LayoutContext } from "../../hooks/layout-context";
import { useTranslation } from "react-i18next";
import useProfile from "../../hooks/use-profile";
import { Permission } from "../../types/permission";
import { useForm } from "react-hook-form";
import { FormInputEmployee } from "../../types/forms";
import { Employee } from "../../types/employee";
import { Link, useNavigate } from "react-router-dom";
import useEmployee from "../../hooks/use-employee";
import { generate } from "../../lib/random-id-lib";
import { ToastsContext } from "../../hooks/toasts-context";
import { UserContext } from "../../hooks/user-context";
import ChangePasswordForm from "./change-password-form";
import ClientForm from "./client-form";
import { Client } from "../../types/client";
import useClient from "../../hooks/use-client";

interface EmployeeFormProps {
  employee?: Employee | null;
  id?: string | null;
  asMyAccount?: boolean;
}

export default function EmployeeForm({ id = null, employee = null, asMyAccount = false }: EmployeeFormProps) {
  const { t } = useTranslation();
  const { layout, setLayout } = useContext(LayoutContext);
  const { user, setUser } = useContext(UserContext);
  const [client, setClient] = useState<Client | null>(null);
  const { getPermissions } = useProfile();
  const { getById } = useClient();
  const { createEmployee, updateEmployee, updateAvatar } = useEmployee();
  const navigate = useNavigate();
  const [permissions, setPermissions] = useState<Array<Permission>>([]);
  const {
    setValue,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<FormInputEmployee>();
  const { toasts, setToasts } = useContext(ToastsContext);
  const [avatar, setAvatar] = useState<any>(null);

  React.useEffect(() => {
    let clientId: string | null = null;
    if (employee !== undefined && employee !== null) {
      const keys: Array<string> = Object.keys(getValues() as FormInputEmployee);
      for (let k of keys) {
        setValue(k as keyof FormInputEmployee, employee[k as keyof Employee]);
      }

      clientId = employee.client || null;
    }

    (async () => {
      if (clientId !== undefined && clientId !== null) {
        const res = await getById(clientId);
        setClient(res);
      }
    })();

    (async () => {
      try {
        const result = await getPermissions();
        setPermissions(result);
        console.log("result", result);
      } catch (e: any) {
        console.log(e);
      }
    })();
  }, []);

  React.useEffect(() => {
    if (employee !== undefined && employee !== null) {
      if (permissions.length > 0) {
        if (employee.permissions !== undefined && employee.permissions !== null && employee.permissions.length > 0) {
          setValue("permissions", employee.permissions);
        }
      }
    }
  }, [permissions]);

  const handleLogin = async (data: any) => {
    console.log("data", data);
    if (id !== undefined && id !== null) {
      try {
        await updateEmployee(id, data);
        await handleAvatarUpload(id);
        toasts.push({ title: t("Sukces"), content: t("Dane zapisane"), show: true, id: generate(32), type: "success" });
        setToasts([...toasts]);
        if (!asMyAccount) {
          navigate("/employee");
        } else {
          const keys: Array<string> = Object.keys(data);
          keys.forEach((k) => (user[k] = data[k]));
          setUser(user);
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      try {
        const result: any = await createEmployee(data);
        await handleAvatarUpload(result.id);
        toasts.push({
          title: t("Błąd"),
          content: t("Nie udało się zapisać"),
          show: true,
          id: generate(32),
          type: "danger",
        });
        setToasts([...toasts]);
        if (data.positionName === "Partner") {
          navigate(`/employee/edit/${result.id}`);
          // navigate(`/employee/${result.id}/assign/client`);
        } else {
          navigate("/employee");
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleAvatarUpload = async (id: string) => {
    if (avatar !== undefined && avatar !== null) {
      const inputData = new FormData();
      inputData.append("file", avatar, avatar.name);
      try {
        await updateAvatar(id, inputData);
      } catch (e: any) {
        console.log(e);
      }
    }
  };

  const selectedAvatar = (e: any) => {
    console.log("selectedAvatar", e);
    try {
      const files: any = e.target.files;
      if (files !== undefined && files !== null && files.length > 0) {
        const file: any = files[0];
        console.log("file", file);
        setAvatar(file);
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  const changedPositionName = (e: any) => {
    if (e !== undefined && e !== null) {
      if (id === undefined || id === null) {
        if (e.target.value === "Partner") {
          setValue("permissions", ["cars", "tuning", "cars.add"]);
        }
      }
    }
  };

  return (
    <>
      <div className="row mb-5">
        <div className="col-12">
          <form onSubmit={handleSubmit(handleLogin)}>
            <div className="card shadow">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <h4 className="header-title">
                    {asMyAccount
                      ? t("Moje konto")
                      : t(id !== undefined && id !== null ? "Edytuj pracownika" : "Dodaj pracownika")}
                  </h4>
                </div>
                <div className="row"></div>
                <div className="row">
                  <div className="col-12 col-md-4 mb-4">
                    <div className="form-group position-relative">
                      <label htmlFor="user_name" className="form-label">
                        {t("Imię i Nazwisko")}
                      </label>
                      <input
                        className={"form-control" + (errors && errors.firstName !== undefined ? " has-error" : "")}
                        id="user_name"
                        {...register("firstName", { required: true })}
                        defaultValue={""}
                      />
                      {errors && errors.firstName !== undefined ? (
                        <span className={"error-message"}>{t(errors.firstName?.type)}</span>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-4 mb-4">
                    <div className="form-group position-relative">
                      <label htmlFor="company_name_short" className="form-label">
                        {t("Adres email")}
                      </label>
                      <input
                        className={"form-control" + (errors && errors.email !== undefined ? " has-error" : "")}
                        id="user_email"
                        {...register("email", { required: true })}
                        defaultValue={""}
                      />
                      {errors && errors.email !== undefined ? (
                        <span className={"error-message"}>{t(errors.email?.type)}</span>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-4 mb-4">
                    <div className="form-group position-relative">
                      <label htmlFor="user_position" className="form-label">
                        {t("Stanowisko")}
                      </label>
                      <select
                        className={"form-control" + (errors && errors.positionName !== undefined ? " has-error" : "")}
                        id="user_position"
                        {...register("positionName", { required: true })}
                        onChange={changedPositionName}
                        defaultValue={""}
                      >
                        <option value={"Tuning"}>{t("Technik")}</option>
                        <option value={"Developer"}>{t("Developer")}</option>
                        <option value={"Partner"}>{t("Partner")}</option>
                        <option value={"Other"}>{t("Other")}</option>
                      </select>
                      {errors && errors.positionName !== undefined ? (
                        <span className={"error-message"}>{t(errors.positionName?.type)}</span>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-4 mb-4">
                    <div className="form-group position-relative">
                      <label htmlFor="phone_short" className="form-label">
                        {t("Numer telefonu")}
                      </label>
                      <input
                        className={"form-control" + (errors && errors.phone !== undefined ? " has-error" : "")}
                        id="phone_short"
                        {...register("phone")}
                        defaultValue={""}
                      />
                      {errors && errors.phone !== undefined ? (
                        <span className={"error-message"}>{t(errors.phone?.type)}</span>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
                {id !== undefined && id !== null ? (
                  <></>
                ) : (
                  <div className="row mb-4">
                    <div className="col-12 col-md-6 mb-4">
                      <div className="form-group position-relative">
                        <label htmlFor="passwoard" className="form-label">
                          {t("Hasło")}
                        </label>
                        <input
                          className={"form-control" + (errors && errors.password !== undefined ? " has-error" : "")}
                          id="passwoard"
                          type={"password"}
                          {...register("password", { required: true })}
                          defaultValue={""}
                        />
                        {errors && errors.password !== undefined ? (
                          <span className={"error-message"}>{t(errors.password?.type)}</span>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-md-6 mb-4">
                      <div className="form-group position-relative">
                        <label htmlFor="passwoard-2" className="form-label">
                          {t("Powtórz hasło")}
                        </label>
                        <input
                          className={
                            "form-control" + (errors && errors.passwordRepeat !== undefined ? " has-error" : "")
                          }
                          id="passwoard-2"
                          type={"password"}
                          {...register("passwordRepeat", { required: true })}
                          defaultValue={""}
                        />
                        {errors && errors.passwordRepeat !== undefined ? (
                          <span className={"error-message"}>{t(errors.passwordRepeat?.type)}</span>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                <div className="d-flex justify-content-between align-items-center mb-4">
                  <h6 className="header-title">{t("Zdjęcie profilowe")}</h6>
                </div>
                <div className="row mb-5">
                  <div className={"col-12"}>
                    <div className={"d-flex align-items-center"}>
                      <label htmlFor={"avatar"} className={"btn btn-outline-primary"}>
                        {t("Wybierz plik")}
                      </label>
                      <div className={"ml15"}>{avatar !== null ? avatar.name : ""}</div>
                    </div>
                    <div className="hidden">
                      <input type={"file"} id={"avatar"} name={"avatar"} onChange={selectedAvatar} />
                    </div>
                  </div>
                </div>

                {!asMyAccount ? (
                  <>
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <h6 className="header-title">{t("Prawa dostępu")}</h6>
                    </div>
                    <div className="row">
                      {permissions.map((it) =>
                        it.permissions!!.map((item) => (
                          <div className="col-12 col-md-2 mb-4" key={`Permission${item.id}`}>
                            <div className="form-check form-switch mb-2">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                {...register("permissions")}
                                value={item.ekey}
                                id={`Permission${item.id}`}
                              />
                              <label className="form-check-label" htmlFor={`Permission${item.id}`}>
                                {item.title}
                              </label>
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div className="card-footer p-4 text-muted">
                <div className="row">
                  <div className="col-12 text-end">
                    {asMyAccount ? (
                      <button className="btn btn-outline-primary">{t("Zapisz")}</button>
                    ) : (
                      <>
                        <Link className="btn btn-outline-primary" to={"/employee"}>
                          {t("Anuluj")}
                        </Link>
                        {id !== undefined && id !== null ? (
                          <button className="btn btn-outline-primary">{t("Zapisz")}</button>
                        ) : (
                          <button className="btn btn-outline-primary">{t("Dodaj")}</button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      {!asMyAccount && id !== undefined && id !== null && client !== undefined && client !== null ? (
        <ClientForm id={employee!!.client} client={client} employeeId={id} employee={employee} asPartner={true} />
      ) : (
        <></>
      )}

      {asMyAccount && id !== undefined && id !== null && client !== undefined && client !== null ? (
        <ClientForm id={employee!!.client} client={client} employeeId={id} employee={employee} asPartner={true} />
      ) : (
        <></>
      )}

      {asMyAccount || (id !== undefined && id !== null) ? <ChangePasswordForm id={id!!} employee={employee} /> : <></>}
    </>
  );
}
