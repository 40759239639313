import { useContext } from "react";
import axios from "axios";
import { API_URL } from "../config";
import { getHeaderOptions } from "../lib/request-helper";
import { ShowLoaderContext } from "./show-loader-context";
import { CarType } from "../types/car";
import { FormInputCarType } from "../types/forms";

export default function useCarType() {
  const { setShowLoader } = useContext(ShowLoaderContext);

  const listing = async (
    page: number,
    perPage: number,
    search: string = "",
    sortField: string | null = null,
    sortDirection: string | null = null
  ): Promise<any> => {
    setShowLoader(true);
    try {
      const result = await axios.get(
        `${API_URL}/car/type?page=${page}&perPage=${perPage}&search=${search}&sortProp=${sortField}&sortDir=${sortDirection}`,
        getHeaderOptions()
      );
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const listingSimpleCarTypes = async (): Promise<any> => {
    setShowLoader(true);
    try {
      const result = await axios.get(`${API_URL}/car/type/simple`, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const getById = async (id: string): Promise<CarType> => {
    setShowLoader(true);
    try {
      const result = await axios.get(`${API_URL}/car/type/${id}`, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const createCarType = async (inputData: FormInputCarType): Promise<CarType> => {
    setShowLoader(true);
    try {
      const result = await axios.post(`${API_URL}/car/type`, inputData, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const updateCarType = async (id: string, inputData: FormInputCarType): Promise<CarType> => {
    setShowLoader(true);
    try {
      const result = await axios.put(`${API_URL}/car/type/${id}`, inputData, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const forceDeleteCarType = async (id: string): Promise<CarType> => {
    setShowLoader(true);
    try {
      const result = await axios.delete(`${API_URL}/car/type/${id}`, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  return {
    listing,
    listingSimpleCarTypes,
    getById,
    createCarType,
    updateCarType,
    forceDeleteCarType,
  };
}
