import React, { useContext } from "react";
import { LayoutContext } from "../hooks/layout-context";
import { useTranslation } from "react-i18next";

export default function MissingPermission() {
  const { layout, setLayout } = useContext(LayoutContext);
  const { t } = useTranslation();

  React.useEffect(() => {
    setLayout({
      ...layout,
      sideMenu: "",
    });
  }, []);

  return (
    <div>
      <h1>{t("Błąd")}</h1>
      <div>{t("Brak uprawnień")}</div>
    </div>
  );
}
