import { useContext } from "react";
import axios from "axios";
import { API_URL } from "../config";
import { getHeaderOptions } from "../lib/request-helper";
import { ShowLoaderContext } from "./show-loader-context";
import { Permission } from "../types/permission";

export default function useProfile() {
  const { setShowLoader } = useContext(ShowLoaderContext);

  const changeLanguage = async (lang: string) => {
    setShowLoader(true);
    try {
      const result = await axios.put(`${API_URL}/profile`, { appLanguage: lang }, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const getPermissions = async (): Promise<Array<Permission>> => {
    setShowLoader(true);
    try {
      const result = await axios.get(`${API_URL}/permission`, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  return {
    changeLanguage,
    getPermissions,
  };
}
