import { useContext } from "react";
import axios from "axios";
import { API_URL } from "../config";
import { getHeaderOptions } from "../lib/request-helper";
import { ShowLoaderContext } from "./show-loader-context";
import { CarBrand } from "../types/car";
import { FormInputCarBrand } from "../types/forms";

export default function useCarBrand() {
  const { setShowLoader } = useContext(ShowLoaderContext);

  const listing = async (
    page: number,
    perPage: number,
    search: string = "",
    sortField: string | null = null,
    sortDirection: string | null = null
  ): Promise<any> => {
    setShowLoader(true);
    try {
      const result = await axios.get(
        `${API_URL}/car/brand?page=${page}&perPage=${perPage}&search=${search}&sortProp=${sortField}&sortDir=${sortDirection}`,
        getHeaderOptions()
      );
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const listingSimpleCarBrands = async (): Promise<any> => {
    setShowLoader(true);
    try {
      const result = await axios.get(`${API_URL}/car/brand/simple`, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const getById = async (id: string): Promise<CarBrand> => {
    setShowLoader(true);
    try {
      const result = await axios.get(`${API_URL}/car/brand/${id}`, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const createCarBrand = async (inputData: FormInputCarBrand): Promise<CarBrand> => {
    setShowLoader(true);
    try {
      const result = await axios.post(`${API_URL}/car/brand`, inputData, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const updateCarBrand = async (id: string, inputData: FormInputCarBrand): Promise<CarBrand> => {
    setShowLoader(true);
    try {
      const result = await axios.put(`${API_URL}/car/brand/${id}`, inputData, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const forceDeleteCarBrand = async (id: string): Promise<CarBrand> => {
    setShowLoader(true);
    try {
      const result = await axios.delete(`${API_URL}/car/brand/${id}`, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  return {
    listing,
    listingSimpleCarBrands,
    getById,
    createCarBrand,
    updateCarBrand,
    forceDeleteCarBrand,
  };
}
