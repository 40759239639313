import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { FormInputEmployee } from "../../types/forms";
import { Employee } from "../../types/employee";
import useEmployee from "../../hooks/use-employee";
import { generate } from "../../lib/random-id-lib";
import { ToastsContext } from "../../hooks/toasts-context";

interface EmployeeFormProps {
  employee?: Employee | null;
  id: string;
}

export default function ChangePasswordForm({ id, employee = null }: EmployeeFormProps) {
  const { t } = useTranslation();
  const { changePassword } = useEmployee();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormInputEmployee>();
  const { toasts, setToasts } = useContext(ToastsContext);
  const password = React.useRef({});
  password.current = watch("password", "");

  const handleLogin = async (data: any) => {
    console.log("data", data);
    try {
      await changePassword(id, data);
      toasts.push({ title: t("Sukces"), content: t("Dane zapisane"), show: true, id: generate(32), type: "success" });
      setToasts([...toasts]);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="row mb-5">
      <div className="col-12">
        <form onSubmit={handleSubmit(handleLogin)}>
          <div className="card shadow">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h4 className="header-title">{t("Zmiana hasła")}</h4>
              </div>
              <div className="row"></div>
              <div className="row mb-4">
                <div className="col-12 col-md-6 mb-4">
                  <div className="form-group position-relative">
                    <label htmlFor="passwoard" className="form-label">
                      {t("Hasło")}
                    </label>
                    <input
                      className={"form-control" + (errors && errors.password !== undefined ? " has-error" : "")}
                      id="passwoard"
                      type={"password"}
                      {...register("password", { required: true, minLength: 3 })}
                      defaultValue={""}
                    />
                    {errors && errors.password !== undefined ? (
                      <span className={"error-message"}>{t(errors.password?.type)}</span>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="col-12 col-md-6 mb-4">
                  <div className="form-group position-relative">
                    <label htmlFor="passwoard-2" className="form-label">
                      {t("Powtórz hasło")}
                    </label>
                    <input
                      className={"form-control" + (errors && errors.passwordRepeat !== undefined ? " has-error" : "")}
                      id="passwoard-2"
                      type={"password"}
                      {...register("passwordRepeat", {
                        required: true,
                        minLength: 3,
                        validate: (value) => value === password.current || t("Hasła nie są takie same"),
                      })}
                      defaultValue={""}
                    />
                    {errors && errors.passwordRepeat !== undefined ? (
                      <span className={"error-message"}>
                        {errors.passwordRepeat?.type === "validate"
                          ? errors.passwordRepeat?.message
                          : t(errors.passwordRepeat?.type)}
                      </span>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer p-4 text-muted">
              <div className="row">
                <div className="col-12 text-end">
                  <button className="btn btn-outline-primary">{t("Zapisz")}</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
