import React, { useContext, useState } from "react";
import { LayoutContext } from "../../hooks/layout-context";
import { useTranslation } from "react-i18next";
import CarForm from "../../components/forms/car-form";
import { useParams } from "react-router-dom";
import { Car } from "../../types/car";
import useCar from "../../hooks/use-car";

export default function CarsEdit() {
  const { t } = useTranslation();
  const { layout, setLayout } = useContext(LayoutContext);
  const params = useParams();
  const { getById } = useCar();
  const [car, setCar] = useState<Car | null>();

  React.useEffect(() => {
    console.log("params", params);
    if (params !== undefined && params !== null) {
      if (params.id !== undefined && params.id !== null) {
        (async () => {
          const result = await getById(params.id!!);
          setCar(result);
        })();
      }
    }
  }, []);

  React.useEffect(() => {
    layout.sideMenu = "cars.add";
    layout.breadcrumbs = [
      { label: t("Pojazdy"), path: "/cars" },
      { path: null, label: t("Edytuj") },
    ];
    setLayout({ ...layout });
  }, []);

  return car !== undefined && car !== null ? <CarForm id={params.id} car={car} asTuning={false} /> : <></>;
}
